/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import wrapWithProvider from './wrap-with-provider';

import './src/pages/index.scss';

export const wrapRootElement = wrapWithProvider;
