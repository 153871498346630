import { createStore as reduxCreateStore } from 'redux';
import reducer from './reducer';
import { defaultLabel } from '../utils/js/default-label';

const defaultInitialState = {
  label: {
    default: true,
    ...defaultLabel(),
  },
};

const createStore = () => reduxCreateStore(reducer, defaultInitialState);
export default createStore;
