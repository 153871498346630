import {
  SET_SHAPE, SET_IMAGE, SET_BORDER, SET_TEXT, SET_PRINT, SET_LABEL,
} from './action-types';

export default function (state, action) {
  switch (action.type) {
    case SET_LABEL: {
      const { label } = action.payload;
      return {
        ...state,
        label,
      };
    }
    case SET_SHAPE: {
      const { shape } = action.payload;
      return {
        ...state,
        label: {
          ...state.label,
          shape,
        },
      };
    }
    case SET_IMAGE: {
      const { image } = action.payload;
      return {
        ...state,
        label: {
          ...state.label,
          image,
        },
      };
    }
    case SET_BORDER: {
      const { border } = action.payload;
      return {
        ...state,
        label: {
          ...state.label,
          border,
        },
      };
    }
    case SET_TEXT: {
      const { text } = action.payload;
      return {
        ...state,
        label: {
          ...state.label,
          text: {
            ...state.label.text,
            ...text,
          },
        },
      };
    }
    case SET_PRINT: {
      const { print } = action.payload;
      return {
        ...state,
        label: {
          ...state.label,
          print: {
            ...state.label.print,
            ...print,
          },
        },
      };
    }
    default:
      return state;
  }
}
