import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
// TODO: Load correct locale
import * as localeFR from 'dayjs/locale/fr';
import * as localeEN from 'dayjs/locale/en';

export const loadDayjs = (locale = process.env.GATSBY_LANG || 'en') => {
  dayjs.extend(relativeTime);

  switch (locale) {
    case 'fr':
      dayjs.locale(localeFR);
      break;
    case 'en':
    default:
      dayjs.locale(localeEN);
      break;
  }
};

export const fromNow = (date) => dayjs(date).fromNow();

export const getFullDate = (day = true, month = true, year = true) => {
  const today = new Date();
  const lang = process.env.GATSBY_LANG || 'en';

  const months = {
    en: [
      'January',
      'Febuary',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    fr: [
      'Janvier',
      'Février',
      'Mars',
      'Avril',
      'Mai',
      'Juin',
      'Juillet',
      'Août',
      'Septembre',
      'Octobre',
      'Novembre',
      'Décembre',
    ],
  };

  return `${day ? today.getDate() : ''} ${month ? months[lang][today.getMonth()] : ''} ${year ? today.getFullYear() : ''}`;
};
