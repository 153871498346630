// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-guestbook-jsx": () => import("./../../../src/pages/guestbook.jsx" /* webpackChunkName: "component---src-pages-guestbook-jsx" */),
  "component---src-pages-help-jsx": () => import("./../../../src/pages/help.jsx" /* webpackChunkName: "component---src-pages-help-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-news-jsx": () => import("./../../../src/pages/news.jsx" /* webpackChunkName: "component---src-pages-news-jsx" */),
  "component---src-pages-page-2-jsx": () => import("./../../../src/pages/page-2.jsx" /* webpackChunkName: "component---src-pages-page-2-jsx" */),
  "component---src-templates-border-jsx": () => import("./../../../src/templates/border.jsx" /* webpackChunkName: "component---src-templates-border-jsx" */),
  "component---src-templates-image-jsx": () => import("./../../../src/templates/image.jsx" /* webpackChunkName: "component---src-templates-image-jsx" */),
  "component---src-templates-print-jsx": () => import("./../../../src/templates/print.jsx" /* webpackChunkName: "component---src-templates-print-jsx" */),
  "component---src-templates-result-jsx": () => import("./../../../src/templates/result.jsx" /* webpackChunkName: "component---src-templates-result-jsx" */),
  "component---src-templates-shape-jsx": () => import("./../../../src/templates/shape.jsx" /* webpackChunkName: "component---src-templates-shape-jsx" */),
  "component---src-templates-text-jsx": () => import("./../../../src/templates/text.jsx" /* webpackChunkName: "component---src-templates-text-jsx" */)
}

