module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#164e79","theme_color":"#164e79","display":"minimal-ui","icon":"src/images/logo-forme.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"8db5bb3709339e13482f39d4a5b1c705"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/home/maxime/Projects/mesetiquettes/mesetiquettes/src/locales","languages":["en","fr"],"defaultLanguage":"fr","redirect":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[],"excludePattern":"(excluded-link|external)"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
