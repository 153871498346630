import JSONData from '../../conf/label-maker.json';
import { getFullDate } from './date';

export const defaultLabel = (category) => {
  const labelCategory = category || 'default';
  const imagesCategory = JSONData?.categories[labelCategory]?.illustrations[0] || 'decoration';

  let date;
  switch (category) {
    case 'jam':
    case 'preserve':
      date = getFullDate(false, true, true);
      break;
    case 'bottle':
    case 'school':
      date = getFullDate(false, false, true);
      break;
    default:
      date = '';
      break;
  }

  return {
    category: labelCategory,
    shape: JSONData?.categories[labelCategory]?.shapes[0] || 'rectangle',
    image: JSONData?.images[imagesCategory][0] || 'deco1',
    border: 'black',
    text: {
      line1: 'Mon étiquette',
      line2: '',
      date,
      color: 'black',
      font: 'ubuntu',
      size: 16,
    },
    print: {
      width: 8,
      number: 1,
    },
  };
};
